import { chain_status } from "config";

const ContractAddressesList = {
  369: {
    router: "0x165C3410fC91EF562C50559f7d2289fEbed552d9", // pc main
    factory: "0x29ea7545def87022badc76323f373ea1e707c523", // pc main
    dai: "0xefD766cCb38EaF1dfd701853BFCe31359239F305", // pc main $weDAI
    wpls: "0xa1077a294dde1b09bb078844df40758a5d0f9a27", // pc main
    usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // pc main $pUSDC ($weUSDC: 0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07)
    usdcLp: "0x8F6DfB2Fa2f7Ccf9d7106E96207d8B947a89998a", // pc main pUSDC:WPLS

    snow: "0x336acF318912D1a35D35F153a3F7A4366dF3F21C", // SnowToken_v0.5: 0xdc318Ea55e15Fd480a7c9baEc6d957Cf602b5063
    bill: "0xc0F1BA2780bBb41363d94859D8EBC26809dcC010", // legacy?
    snowWplslp: "0x1010C144a109542f93be493A702df0fF1aB7547C", // SNOW(v0.5):WPLS (100K:100K): 0xB564f77D0E4F295f4b0E8528c15b7fb432aB1D34
    billWplslp: "0x1C2f109e263DF5575751A83e071Cd55C7f4Ac096", // legacy?
    nft: "0xbD305846149C732a89E08605D766FE42A7058dAc", // SnowNFT_v0.4: 0xb9520710e38b8D83554962D2e4Fc0D311653D176
    zap: "0x657Bf84666fe67EB5836694c1341A114C530f92E", // ZapV3_v0.4: 0x983389Eee6Bb76419b0176d64E1dB8191213A0B5
    masterChef: "0xa300D20A3A6342411424c1bF2887Ae7Be291bc28", // MasterChef_v0.5: 0x520E7c83B0859FC6915AcC75A7b4d4FD256F97FA
    stake: "0x50c59f6ac05ed909750f8ab3d6539492b36fe4bc", // ?
    stakeToken: "0x6C99174867C1f41a2DfE0b196A667e6130Cf3C61", // ?
    lib: "0xBDd60db32015bD0ED6A4aC0bc9B1F9d78f8BB1AA", // SnowLib_v0.1: 0x1c815565415116081Bc5b5DF359F41bdF866735e (LEFT OFF HERE ... need SnowLib_v0.2: 0x3e833F280b223CDaBDb52a16d8cf45A83B757d77)

    // NOTE: 'config' & 'snowConfig' are both referencing SnowConfig.sol
    config: "0xEED3283BC74E3f843F391D870ef026D1D0Ce5F03", // SnowConf_v0.3: 0xD0B984883A640FeEC527014B7364C0d94061e006
    snowConfig: "0xEED3283BC74E3f843F391D870ef026D1D0Ce5F03", // SnowConf_v0.4: 0x790C736Fd431420086a2b4C9505BADAf0676B8B9
  },
  146: {
    router: "0xa6AD18C2aC47803E193F75c3677b14BF19B94883",
    factory: "0xEE4bC42157cf65291Ba2FE839AE127e3Cc76f741",
    dai: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
    wpls: "0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38",
    scUSD: "0xd3DCe716f3eF535C5Ff8d041c1A41C3bd89b97aE",
    usdc: "0x29219dd400f2Bf60E5a23d13Be72B486D4038894",
    usdcLp: "0x8F6DfB2Fa2f7Ccf9d7106E96207d8B947a89998a",

    snow: "0x1311B48fe06FE6e53882f98c4075c9E018EeC71F",
    bill: "0xc0F1BA2780bBb41363d94859D8EBC26809dcC010",
    snowWplslp: "0xF505a27832d3465559ecD1CbF7bEe147595EB271",
    billWplslp: "0x1C2f109e263DF5575751A83e071Cd55C7f4Ac096",
    nft: "0xE682A6D337fc9f8F2307A4F41d2c0ed9EE9043cb",
    nftSale: "0x8426a315632Cd58Dc904642dF89E0Dd7Cb87d7A5",
    zap: "0x33b09FDbdf4B140c9d0097a6eb33539E4036aDB8",
    masterChef: "0x4b64D2df8f1cbe5984D0Ceba7ee1B87629164D84",
    stake: "0x50c59f6ac05ed909750f8ab3d6539492b36fe4bc",
    stakeToken: "0x6C99174867C1f41a2DfE0b196A667e6130Cf3C61",
    lib: "0x64046753649e0622Fc883eb417A8BC68DcB01578",

    config: "0x323293c36B67c7B76D3a8C9E3feEd67FE4fAAa2B",
    snowConfig: "0x323293c36B67c7B76D3a8C9E3feEd67FE4fAAa2B",
  },
};

const contractAddresses = ContractAddressesList[chain_status];

export default contractAddresses;
