import React from "react";

import Swap from "pages/Swap";
import NotFound from "pages/Notfound";
import Farms from "pages/Farms";
import NFTPresale from "pages/NFTPresale";
import Roadmap from "pages/Roadmap";
import ComingSoon from "pages/ComingSoon";
import Refer from "pages/Refer";
import MyNFT from "pages/MyNFT";

const router = [
  {
    path: "/",
    element: <Farms />,
  },
  {
    path: "/nfts",
    element: <NFTPresale />,
  },
  {
    path: "/mynft",
    element: <MyNFT />,
  },
  {
    path: "/roadmap",
    element: <Roadmap />,
  },
  {
    path: "/stake",
    element: <Farms />,
  },
  {
    path: "/swap",
    element: <Swap />,
  },
  {
    path: "/game",
    element: <ComingSoon />,
  },
  {
    path: "/bank",
    element: <ComingSoon />,
  },
  // {
  //   path: "/refer",
  //   element: <Refer />,
  // },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default router;
