import React, { useEffect, useState } from "react";
import FarmStaking from "./StakingInfo";
import TotalValueLocked from "components/FarmsComponents/TotalValueLocked";
import { getSNOWContract } from "utils/contractHelpers";
import httpProvider from "utils/providerHelpers";
import { useAccount } from "wagmi";
import { toReadableAmount } from "utils/customHelpers";
import useRefresh from "hooks/useRefresh";

export default function FarmBanner() {
  const { address } = useAccount();
  const snowContract = getSNOWContract(httpProvider);
  const [balance, setBalance] = useState("0");
  const { slowRefresh } = useRefresh();
  
  useEffect(() => {
    async function getBalance() {
      if (address) {
        const myBalance = await snowContract.balanceOf(address);
        setBalance(toReadableAmount(myBalance, 18, 4));
      }
    }
    getBalance();
  }, [address, slowRefresh]);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:mt-3 my-3 gap-3">
      <div className="flex-col items-center justify-center w-full snow_effect_card  p-1 flex md:hidden xl:flex">
        <div className="">
          <a href="/swap">
            <img
              src="/assets/banner.webp"
              alt="SNOW BANK"
              srcSet=""
              className=" max-w-[270px] w-full mx-auto my-auto"
            />
          </a>
        </div>
        <div className="text-lg">
          My SNOW Balance:{" "}
          <span className="text-green-500 text-xl">{balance} SNOW</span>
        </div>
      </div>
      <FarmStaking />
      <TotalValueLocked />
    </div>
  );
}
