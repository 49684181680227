import { CHAIN_ID } from "config";
import { providers } from "ethers";
import getRpcUrl from "utils/getRpcUrl";
import { chain_status } from "config";

const NETWORK_NAME_LIST = {
  369: "pls",
  146: "sonic",
};

const RPC_URL = getRpcUrl();
const network = {
  chainId: CHAIN_ID,
  name: NETWORK_NAME_LIST[chain_status],
  ensAddress: undefined,
};
const httpProvider = new providers.JsonRpcProvider(RPC_URL, network);

// const RPC_URL = getRpcUrl();
// const network = {
//   chainId: 1,
//   name: "PLS Smart Chain",
//   ensAddress: undefined,
// };
// const httpProvider = new providers.JsonRpcProvider(RPC_URL, network);

export default httpProvider;
