import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetDexApiData } from "state/hooks";

const DexApiDataContext = createContext();

export const DexApiDataProvider = ({ children }) => {
    const [dexApiDataVar, setDexApiDataVar] = useState({});
    const dexData = useGetDexApiData();

    useEffect(() => {
        setDexApiDataVar(dexData);
    }, [dexData]);

    return (
        <DexApiDataContext.Provider value={{ dexApiDataVar, setDexApiDataVar }}>
            {children}
        </DexApiDataContext.Provider>
    );
};

export const useDexApiDataContext = () => useContext(DexApiDataContext);
