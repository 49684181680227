import { ethers } from "ethers";
import { getMasterChefAddress, getSnowWplsLpAddress } from "utils/addressHelpers";

import {
  didUserReject,
  fromReadableAmount,
  toReadableAmount,
} from "./customHelpers";
import { limitedFunction } from "./limitHelper";
import { notify } from "./toastHelper";
import BigNumber from "bignumber.js";

export const approve = async (
  lpContract,
  masterChefContract,
  address,
  isNFTPool
) => {
  console.log('--start Approve--')
  return (await isNFTPool)
    ? lpContract.setApprovalForAll(masterChefContract.address, true, {
        from: address,
      })
    : lpContract.approve(
        masterChefContract.address,
        ethers.constants.MaxUint256,
        { from: address }
      );
};

export const stake = async (
  masterChefContract,
  pid,
  amount,
  decimals = 18,
  isNFTPool,
  isNFTALL
) => {
  try {
    const tx = await masterChefContract.stake(
      pid,
      isNFTPool ? Number(amount) : fromReadableAmount(amount, decimals),
    );
    await tx.wait();
    notify("success", "Transaction successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
    }
    return null;
  }
};

export const unstake = async (
  masterChefContract,
  pid,
  amount,
  address,
  decimals = 18,
  isNFTPool,
  isNFTALL
) => {
  try {
    const tx = await masterChefContract.withdraw(
      pid,
      isNFTPool
        ? Number(amount)
        : fromReadableAmount(amount, decimals),
      { from: address }
    );
    await tx.wait();
    notify("success", "Transaction successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
    }
    return null;
  }
};

export const swap = async (
  zapContract,
  tokenA,
  isNative,
  amount,
  tokenB,
  isNativeOut,
  address
) => {
  try {
    const tx = await zapContract.swapTokens(
      isNative? '0x0000000000000000000000000000000000000000' : tokenA,
      isNativeOut? '0x0000000000000000000000000000000000000000' : tokenB,
      amount,
      0,
      { from: address, value: isNative? amount : 0}
    );
    await tx.wait();
    notify("success", "Zap successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
      console.log(e);
    }
    return null;
  }
};

export const zapForFarm = async (
  zapContract,
  tokenA,
  isNative,
  amount,
  pid,
  address
) => {
  try {
    console.log(pid, isNative? '0x0000000000000000000000000000000000000000' : tokenA, amount, isNative, address);
    const tx = await zapContract.zapIn(
      pid,
      isNative? '0x0000000000000000000000000000000000000000' : tokenA,
      amount,
      { from: address, value: isNative? amount : 0 }
    );
    await tx.wait();
    return notify("success", "Transaction successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
    }
    return null;
  }
};

export const register = async (
  masterChefContract,
  eoa,
  customURN
) => {
  console.log(eoa, customURN, "---register---")
  try {
    const tx = await masterChefContract.registerPromotor(
      eoa,
      customURN
    );
    await tx.wait();
    notify("success", "Transaction successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
    }
    return null;
  }
};

export const harvest = async (masterChefContract, pid, address) => {
  // const res = await limitedFunction(false, address);
  // if (!res?.success) {
  //   notify("error", "You can not harvest or compound three times a day.");
  //   return false;
  // }

  try {
    const tx = await masterChefContract.claimRewards(pid);
    await tx.wait();
    limitedFunction(true, address);
    notify("success", "Harvest successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
      console.log(e);
    }
    return null;
  }
};

export const harvestMany = async (masterChefContract, pids, address) => {
  const res = await limitedFunction(false, address);
  if (!res?.success) {
    notify("error", "You can not harvest or compound three times a day.");
    return false;
  }

  try {
    const tx = await masterChefContract.harvestMany(pids, {
      from: address,
    });
    await tx.wait();
    limitedFunction(true, address);
    notify("success", "Harvest All successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
    }
    return null;
  }
};

export const compound = async (masterChefContract, pid, address) => {
  const res = await limitedFunction(false, address);
  if (!res?.success) {
    notify("error", "You can not harvest or compound three times a day.");
    return false;
  }

  try {
    const tx = await masterChefContract.Compound(pid);
    await tx.wait();
    limitedFunction(true, address);
    notify("success", "Harvest successful!");
  } catch (e) {
    if (didUserReject(e)) {
      notify("error", "User rejected transaction");
    } else {
      notify("error", e.reason);
      console.log(e);
    }
    return null;
  }
};
