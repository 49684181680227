import React from "react";
import { ToastContainer } from "react-toastify";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { RainbowKitProvider, getDefaultConfig} from "@rainbow-me/rainbowkit";

import {
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  rabbyWallet
} from '@rainbow-me/rainbowkit/wallets';


import { WagmiProvider,  http} from "wagmi";
import { base, avalanche, bsc } from "wagmi/chains";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { RefreshContextProvider } from "context/RefreshContext";
import ContractContextProvideer from "context/contracts";
import { ThemeContextProvider } from "context/ThemeContext";
import { LanguageProvider } from "context/Localization";
import { ModalProvider } from "uikit";
import { ALCHEMY_ID } from "config";
import store from "state";

export const sonic = {
  id: 146,
  name: 'Sonic',
  iconUrl: 'https://www.soniclabs.com/apple-icon.png?0afb6d97a9fd9393',
  network: 'sonic',
  nativeCurrency: {
    decimals: 18,
    name: 'sonic',
    symbol: 'S',
  },
  rpcUrls: {
    public: { http: ['https://sonic-mainnet.g.alchemy.com/v2/UwROBWWhYm4TWOf1JpEqhqtemurAJM3d'] },
    default: { http: ['https://sonic-mainnet.g.alchemy.com/v2/UwROBWWhYm4TWOf1JpEqhqtemurAJM3d'] },
  }
}

export const pulsechain = {
  id: 369,
  name: 'PulseChain',
  iconUrl: 'https://assets.coingecko.com//coins/images/25666/large/PLS-LogoTransparent_1.png?1712339803',
  nativeCurrency: { name: 'Pulse', symbol: 'PLS', decimals: 18 },
  testnet: false,
  rpcUrls: {
    default: {
      http: ['https://rpc.pulsechain.com'],
      webSocket: ['wss://ws.pulsechain.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'PulseScan',
      url: 'https://scan.pulsechain.com',
      apiUrl: 'https://api.scan.pulsechain.com/api',
    },
  },
  contracts: {
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    },
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601,
    },
  },
}

const config = getDefaultConfig({
  appName: 'snowbase.io',
  projectId: '85ea32d265dfc865d0672c8b6b5c53d2',
  chains: [pulsechain, sonic, avalanche, base],
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, walletConnectWallet, rabbyWallet, coinbaseWallet],
    },
  ],
  multiInjectedProviderDiscovery: false,
  /* Wagmi createConfig options including `transports` are also accepted */
})

// const config = getDefaultConfig({
//   appName: 'snow demo',
//   projectId: '85ea32d265dfc865d0672c8b6b5c53d2',
//   chains: [pulsechain, bsc],
//   wallets: [rainbowWallet],
//   transports: {
//     [pulsechain.id]: http(),
//     [bsc.id]: http(),
//   },
// })

const queryClient = new QueryClient()

const Providers = ({ children }) => {
  // const { chains, publicClient } = configureChains(
  //   [pulsechain, base, sonic, avalanche, bsc],
  //   [alchemyProvider({ apiKey: ALCHEMY_ID }), publicProvider()]
  // );

  // const { connectors } = getDefaultWallets({
  //   appName: "snowbase.io",
  //   projectId: "85ea32d265dfc865d0672c8b6b5c53d2",
  //   chains,
  // });



  // const wagmiConfig = createConfig({
  //   autoConnect: true,
  //   connectors,
  //   publicClient,
  // });


  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Provider store={store}>
            <HelmetProvider>
              <ThemeContextProvider>
                <LanguageProvider>
                  <RefreshContextProvider>
                    <ContractContextProvideer>
                      <ModalProvider>{children}</ModalProvider>
                    </ContractContextProvideer>
                  </RefreshContextProvider>
                </LanguageProvider>
              </ThemeContextProvider>
            </HelmetProvider>
            <ToastContainer />
          </Provider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default Providers;
