import tokens from "./tokens";
import addresses from "constants/addresses";
import { NATIVE_COIN_SYMBOL } from "config";

export const liquidityList = [
  {
    pid: 0,
    lpSymbol: tokens.snow.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.snow.address,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.pls.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.pls.address,
    decimals: 18,
    logoA: tokens.pls.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.wpls.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wpls.address,
    decimals: 18,
    logoA: tokens.wpls.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: `W${NATIVE_COIN_SYMBOL}-SNOW`,
    isTokenOnly: false,
    lpAddresses: addresses.snowWplslp,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: tokens.wpls.logo,
  },
];

export const zapList = [
  {
    pid: 0,
    lpSymbol: tokens.snow.symbol,
    symbol: "TEST2",
    isTokenOnly: true,
    lpAddresses: tokens.snow.address,
    decimals: 18,
    logoA: tokens.snow.logo,
  },
  {
    pid: 0,
    lpSymbol: tokens.pls.symbol,
    symbol: `W${NATIVE_COIN_SYMBOL}`,
    isTokenOnly: true,
    lpAddresses: tokens.pls.address,
    decimals: 18,
    logoA: tokens.pls.logo,
  },
  {
    pid: 0,
    lpSymbol: tokens.wpls.symbol,
    symbol: `W${NATIVE_COIN_SYMBOL}`,
    isTokenOnly: true,
    lpAddresses: tokens.wpls.address,
    decimals: 18,
    logoA: tokens.wpls.logo,
  },
  {
    pid: 0,
    lpSymbol: tokens.usdc.symbol,
    symbol: 'USDC',
    isTokenOnly: true,
    lpAddresses: tokens.usdc.address,
    decimals: 6,
    logoA: tokens.usdc.logo,
  },
  // {
  //   pid: 0,
  //   lpSymbol: tokens.dai.symbol,
  //   symbol: "DAI",
  //   isTokenOnly: true,
  //   lpAddresses: tokens.dai.address,
  //   decimals: 18,
  //   logoA: tokens.dai.logo,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: tokens.bill.symbol,
  //   symbol: "TEST1",
  //   isTokenOnly: true,
  //   lpAddresses: tokens.bill.address,
  //   decimals: 18,
  //   logoA: tokens.bill.logo,
  // },
  {
    pid: 1,
    lpSymbol: `SNOW-W${NATIVE_COIN_SYMBOL}`,
    symbol: "TEST2",
    isTokenOnly: false,
    lpAddresses: addresses.snowWplslp,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: tokens.wpls.logo,
    tokenAAddress: tokens.snow.address,
    tokenBAddress: tokens.wpls.address,
  },
  // {
  //   pid: 1,
  //   lpSymbol: `BILL-W${NATIVE_COIN_SYMBOL}`,
  //   symbol: "TEST1",
  //   isTokenOnly: false,
  //   lpAddresses: addresses.billWplslp,
  //   decimals: 18,
  //   logoA: tokens.snow.logo,
  //   logoB: tokens.wpls.logo,
  //   tokenAAddress: tokens.bill.address,
  //   tokenBAddress: tokens.wpls.address,
  // },
];

const farmsConfig = [
  {
    pid: 1,
    lpSymbol: `SNOW-W${NATIVE_COIN_SYMBOL}`,
    lpAddresses: addresses.snowWplslp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.snow,
    quoteToken: tokens.wpls,
    logoA: tokens.snow.logo,
    logoB: tokens.wpls.logo,
  },

  // {
  //   pid: 1,
  //   lpSymbol: "BILL-wPLS",
  //   lpAddresses: addresses.billWplslp,
  //   isTokenOnly: false,
  //   isNFTPool: false,
  //   token: tokens.bill,
  //   quoteToken: tokens.wpls,
  //   logoA: tokens.bill.logo,
  //   logoB: tokens.wpls.logo,
  // },
  {
    pid: 2,
    lpSymbol: "SNOW",
    lpAddresses: addresses.snow,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.snow,
    quoteToken: tokens.snow,
    logoA: tokens.snow.logo,
    logoB: tokens.snow.logo,
  },
  {
    pid: 3,
    lpSymbol: "Snow Bank NFT",
    lpAddresses: addresses.nft,
    isTokenOnly: true,
    isNFTPool: true,
    token: tokens.nft,
    quoteToken: tokens.snow,
    logoA: "/assets/tokens/nft.png",
    logoB: "",
  },
];

export default farmsConfig;
