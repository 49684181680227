import React from 'react';
import { chain_status } from 'config';

const DisableModal = ({ chain_name, onClose }) => {
  const ChainNameList = {
    369: "Pulse",
    146: "Sonic",
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-md flex items-center justify-center z-50">
      <div className='fixed -z-10 w-screen h-screen'>
        {Array.from(Array(100).keys()).map((i) => (
          <div className="snow" key={i}></div>
        ))}
      </div>
      <div className="text-center">
        <p className="text-4xl text-white font-semibold mb-8">Launching on {chain_name} soon!</p>
        <button
          className="w-[200px!important] h-[40px!important]  box-btn-stake py-[8px!important]"
          onClick={() => onClose()}
        >
          Back to {ChainNameList[chain_status]}
        </button>
      </div>
    </div>
  );
};

export default DisableModal;