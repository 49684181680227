import React, { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useERC721, useNFTContract } from 'hooks/useContract';
import { getNFTContract, getMasterchefContract, getErc20Contract } from 'utils/contractHelpers';
import { getNFTAddress, getUSDCAddress } from 'utils/addressHelpers';
import httpProvider from 'utils/providerHelpers';
import { notify } from 'utils/toastHelper';
import { didUserReject } from 'utils/customHelpers';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import multicall from 'utils/multicall';
import nftABI from "config/abis/nft.json";
import { ethers, Signer } from 'ethers';
import BigNumber from 'bignumber.js';
import { toReadableAmount } from 'utils/customHelpers';
import { useERC20 } from 'hooks/useContract';
import { useEthersSigner } from 'hooks/useEthers';
import LogoLoading from "components/LogoLoading";

const nftContract = getNFTContract(httpProvider);
const masterChefContract = getMasterchefContract(httpProvider);

const NFTCard = ({ id, isStaked }) => {
  const navigate = useNavigate();
  const handleStake = () => {
    navigate('/stake');
  };

  return (
    <div className="w-full max-w-[300px] p-4 rounded-lg snow_effect min-h-[400px] flex flex-col justify-between">
      <div>
        <img
          src={`/assets/stickers/NFT.webp`}
          alt="NFT"
          className="w-full border-opacity-30 mb-4"
        />
        <div className="flex justify-between items-center mb-4">
        <p className='flex gap-2'>SNOW NFT #{id}</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {!isStaked && (
          <button
            onClick={handleStake}
            className="main_btn px-3 py-2 text-sm w-full"
          >
            Stake
          </button>
        )}
        <span className={`px-2 py-1 rounded text-sm text-center ${isStaked ? 'bg-green-500/20 text-green-400' : 'bg-yellow-500/20 text-yellow-400'}`}>
          {isStaked ? (
            <>
              <FaLock className="inline mr-1" />
              STAKED
            </>
          ) : (
            <>
              <FaLockOpen className="inline mr-1" />
              UNSTAKED
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default function MyNFT() {
  const navigate = useNavigate();
  const handleNFTs = () => {
    navigate('/nfts');
  };
  const signer = useEthersSigner();
  const usdcAddress = getUSDCAddress();
  const nftAddress = getNFTAddress();
  const USDCContract = useERC20(usdcAddress);
  const { address } = useAccount();
  const [page, setPage] = useState(1);
  const [nftPrice, setNftPrice] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [displayNFTArray, setDisplayNFTArray] = useState([]);
  const [NFTList, setNFTList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const useNftContract = useNFTContract();

  const handleMint = async () => {
    if (!address) {
      notify('error', 'Please connect your wallet');
      return;
    }

    try {
      const myUSDCBalance = await USDCContract.balanceOf(address);

      if (!nftPrice && !myUSDCBalance) {
        return;
      }
      if (Number(myUSDCBalance) <= nftPrice) {
        notify("warning", "Insufficient USDC Balance");
        return;
      }

      async function handleMint() {
        try {
          setIsMinting(true);
          const tx = await useNftContract.buy();
          await tx.wait();
          setIsMinting(false);
          notify('success', 'NFT minted successfully!');
        } catch (error) {
          setIsMinting(false);
          if (didUserReject(error)) {
            notify("warning", "User Rejected transaction");
            return;
          } else {
            notify("warning", error.reason);
            return;
          }
        }
      };

      async function handleApprove() {
        try {
          setIsApproving(true);
          const useUSDCContract = getErc20Contract(usdcAddress, signer);
          const tx = await useUSDCContract.approve(
            nftAddress,
            nftPrice,
            { from: address }
          );
          await tx.wait();
          setIsApproving(false);
          handleMint();
        } catch (error) {
          setIsApproving(false);
          if (didUserReject(error)) {
            notify("warning", "User Rejected transaction");
            return;
          } else {
            notify("warning", error.reason);
            return;
          }
        }
      };

      if (allowance >= nftPrice) {
        handleMint();
      } else  {
        handleApprove();
      }
    } catch (e) {
      if (didUserReject(e)) {
        notify('error', 'User rejected transaction');
      } else {
        notify('error', e.reason || 'Minting failed');
      }
    }
  };

  const handlePageClick = (e) => {
    setPage(1 + e.selected * 8);
  };

  useEffect(() => {
    async function fetchBalance() {
      try {
        const balance = await nftContract.balanceOf(address);
        const unStakedNFTBalance = Array.from({ length: Number(balance) });
        const calls = unStakedNFTBalance.map((_, index) => {
          return {
            address: nftAddress,
            name: "tokenOfOwnerByIndex",
            params: [address, index],
          };
        });
        const rawUnStakedNFTIds = await multicall(nftABI, calls);
        const parsedUnStakedNFTIds = rawUnStakedNFTIds.map((unStakedId) => {
          return {
            id: Number(ethers.BigNumber.from(unStakedId[0])),
            isStaked: false,
          };
        });
        let nftList = [];
        nftList = nftList.concat(parsedUnStakedNFTIds);

        const bigNumberStakedList = await masterChefContract.getStakedNFTIds(3, address);
        bigNumberStakedList.map(bigNum => nftList.push({id: Number(bigNum), isStaked: true}));
        nftList.sort((a, b) => a.id - b.id);
        setNFTList(nftList);
        setDisplayNFTArray(nftList.slice(0, 8));
        setMaxPage(nftList.length);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    if(address)
      fetchBalance();
    async function fetchNFTPrice() {
      try {
        const nftPrice = await nftContract.price();
        setNftPrice(Number(nftPrice));
      } catch (error) {
        console.log(error);
      }
    }
    fetchNFTPrice();
  }, [address]);

  useEffect(() => {
    async function fethchMyAccountState() {
      try {
        const allowance = await USDCContract.allowance(address, nftAddress);
        setAllowance(Number(allowance));
      } catch (error) {
        console.log(error);
      }   
    }
    if(address && !isLoading){
      fethchMyAccountState();
    }      
  }, [isMinting, isApproving, address, isLoading]);

  useEffect(() => {
    setDisplayNFTArray(NFTList.slice(page - 1, page + 7));
  }, [page]);

  return (
    <div className="container max-w-6xl mx-auto px-4 py-8">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold mb-8">SNOW NFT Minting</h1>
        <div className="bg-secondary p-8 rounded-lg max-w-md mx-auto snow_effect">
          <p className="text-2xl mb-4 flex w-full justify-center items-center">Price: {nftPrice == 0 ? <AiOutlineLoading3Quarters className="text-xl font-bold animate-spin" />: `${toReadableAmount(nftPrice, 6)}`} USDC</p>
          <button
            onClick={handleMint}
            disabled={isApproving || isMinting}
            className="main_btn px-8 py-3 text-xl w-full"
          >
            {isApproving
              ? 'Approving...'
              : <>
                  ({isMinting
                    ? 'Minting...'
                    : 'Mint NFT'
                  })
                </>
            }
          </button>
        </div>
      </div>

      <div className="mt-16">
        <h2 className="text-3xl font-bold mb-8">My NFTs</h2>
        {address
          ? <>
              {isLoading? (
                <div className="flex justify-center my-32 sm:my-[200px]">
                  <AiOutlineLoading3Quarters className="text-3xl font-bold animate-spin" />
                </div>
              )
              : (
                <>
                  {displayNFTArray?.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                      {displayNFTArray.map((nft) => (
                        <NFTCard key={nft.id} {...nft}/>
                      ))}
                    </div>
                  ) : (
                    <div className="w-full flex justify-center items-center flex-col">
                      <p className="my-6 text-lg text-center w-full">
                        You don't have any NFT(s).
                      </p>
                      <button
                        className="snow_effect mx-auto py-2 px-3"
                        onClick={handleNFTs}
                      >
                        Buy Now
                      </button>
                    </div>
                  )}
                </>
              )}
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={Math.min(5, Math.ceil(maxPage / 8))}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="pagination"
              />
            </>
          : <div className="flex justify-center text-4xl my-24 sm:my-[120px]">
              Please connect your wallet
            </div>
          }
      </div>
      
      {isApproving && <LogoLoading title="Approving..." />}
      {isMinting && <LogoLoading title="Minting..." />}
    </div>
  );
}