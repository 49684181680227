import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import DisableModal from "components/DisableModal";
import { useAccount, useSwitchChain } from "wagmi";
import { chain_status } from "config";

export default function Layout({ children }) {
  const { chain } = useAccount();
  const { switchChain } = useSwitchChain()
  const closeModal = () => {
    switchChain({ chainId: chain_status})
  };
  const [isModal, setIsModal] = useState(true);
  useEffect(() => {
    if(!chain)
      setIsModal(false);
    else if(chain.id == 146)
      setIsModal(false);
    else setIsModal(true);
  }, [chain]);
  return (
    <>
      {Array.from(Array(100).keys()).map((i) => (
        <div className="snow" key={i}></div>
      ))}
      <div className="w-full min-h-[calc(100vh)] py-[90px] relative px-1 font-cls">
        <Header />
        <div className="flex w-full h-full justify-center pb-6 font-cls">
          {!isModal && children}
        </div>
        <Footer />
      </div>
      <div className="bg-gradient"></div>
      {isModal && <DisableModal chain_name={chain?.name} onClose={closeModal} />}
    </>
  );
}
