import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useURNPromo } from "hooks/useRegister";
import Loading from "components/Loading";

export default function Notfound() {
  const location = useLocation();
  const navigate = useNavigate();
  const customURN = location.pathname.slice(1);
  const [isLoading, setLoading] = useState(false);
  const { onURNPromo } = useURNPromo();
  
  async function fetchPromo() {
    const promotoAddress = await onURNPromo(customURN);
    if (promotoAddress) {
      localStorage.setItem("PromotoAddress", promotoAddress);
      navigate("/");
    } else {
      localStorage.setItem("PromotoAddress", '');
      setLoading(false);
    }
  }

  // fetchPromo();

  return (
    <>
      {isLoading
      ?<div className="mt-40 w-full flex justify-center">
        <Loading size="5xl"/>
      </div>
      :<div className="h-full flex flex-col items-center justify-center">
        <h1 className="text-5xl text-white font-bold mb-8 animate-pulse">
          Page Not Found?
        </h1>
        <p className="text-white text-lg mb-8 text-center">
          Whoops, this is embarassing. <br /> Looks like the page you were looking
          for wasn't found.
        </p>
        <a href="/" className="bg-symbol text-black px-6 py-2 rounded-full cursor-pointer hover:bg-symbolHover transition ease-in-out">Back to Home</a>
      </div>
      }
    </>
  );
}
