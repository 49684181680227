import { chain_status } from "config";
// Array of available nodes to connect to
export const nodes = {
  369: "https://rpc.pulsechain.com/",
  146: "https://sonic-mainnet.g.alchemy.com/v2/UwROBWWhYm4TWOf1JpEqhqtemurAJM3d/",
};

const getNodeUrl = () => {
  // const randomIndex = random(0, nodes.length - 1);
  return nodes[chain_status];
};

export default getNodeUrl;
